import { Injectable } from "@angular/core"

import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"
import { environment } from "./environments/environment"
import * as qs from "qs"
import { ExhibitorModel, ExhibitorUpdateResponseModel } from "./models/exhibitor-model"
import { Pagination, StrapiListWrapper, StrapiSingleWrapper } from "./models/strapi-wrapper"
import { CountryModel } from "./models/country-model"
import { LoginPageModel } from "./models/login-page-model"
import { SingletonPageModel } from "./models/singleton-page-model"
import { BühneModel } from "./models/bühne-model"
import { VortragModel } from "./models/vortrag-model"
import { TagModel } from "./models/tag-model"
import { ProgrammPageModel } from "./models/programm-page-model"
import { Workshop } from "./models/workshop"
import { WorkshopAnmeldung, WorkshopAnmeldungCreateRequest } from "./models/workshop-anmeldung"
import { WorkshopPageModel } from "./models/workshop-page-model"

@Injectable({
  providedIn: "root",
})
export class ApiService {
  // public apiUrl = 'http://localhost:1337'; // Replace with your Strapi API URL
  public apiUrl = environment.apiUrl

  constructor(private http: HttpClient) {}

  getPages(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/pages`)
  }

  getPage(id: string): Observable<any[]> {
    // populate=blocks
    return this.http.get<any[]>(`${this.apiUrl}/api/pages/${id}?populate=ContentZone`)
  }

  getMenu(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/top-menus?sort[0]=Order:asc&populate=deep`)
  }

  getFooterMenu(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/footer-menus?populate=subItems`)
  }


  getTopPageMenu(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/top-page-menus?populate=deep`)
  }

  getPageBySlug(slug: string): Observable<any[]> {
    // populate=blocks
    // http://localhost:1337/api/posts?filters[slug][$eq]=entrada-de-prueba-2
    // http://localhost:1337/api/pages?sort[0]=Slug:asc&filters[Slug][$eq]=info
    return this.http.get<any[]>(`${this.apiUrl}/api/pages?sort[0]=Slug:asc&filters[Slug][$eq]=${slug}&populate=deep`)
  }

  getPageExists(slug: string): Observable<any[]> {
    console.log("getPageExists", slug)
    return this.http.get<any[]>(`${this.apiUrl}/api/pages?sort[0]=Slug:asc&filters[Slug][$eq]=${slug}`)
  }

  getExhibitors(): Observable<any[]> {
    // return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?populate=deep`);
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?`)
  }

  getExhibitorById(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors/${id}?populate=deep`)
  }

  getExhibitorBySlug(slug: string): Observable<any[]> {
    // return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?filters[slug][$eq]=${slug}?populate=deep`);
    // let search = {
    //   "slug": slug,
    //   "page": 1,
    //   "maxPages": 8
    // };

    let query: any = {
      filters: {
        slug: { $eqi: slug },
      },
      populate: "deep",
    }

    let queryString = qs.stringify(query)
    console.log("getExhibitorBySlug query", queryString)
    queryString = decodeURIComponent(queryString)
    console.log("getExhibitorBySlug query", queryString)
    let result = this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?${queryString}`)
    return result

    // if((result as any).data) {
    //   return result;
    // } else {
    //   return throwError(() =>'Exhibitor not found by slug');
    // }
  }

  getExhibitorByLetter(letter: string): Observable<any[]> {
    // populate=blocks
    // http://localhost:1337/api/posts?filters[slug][$eq]=entrada-de-prueba-2
    // http://localhost:1337/api/pages?sort[0]=Slug:asc&filters[Slug][$eq]=info
    const params = { name_start: letter }
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?filters[name][$startsWith]=${letter}&populate=deep`)
  }

  getExhibitorBySchlagwort(schlagwort: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.apiUrl}/api/exhibitors?filters[schlagworte][$containsi]=${schlagwort}&populate=deep`,
    )
  }

  getExhibitorBySchwerpunkt(schwerpunkt: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.apiUrl}/api/exhibitors?filters[schwerpunkte][$containsi]=${schwerpunkt}&populate=deep`,
    )
  }

  getExhibitorBySchlagwortNeu(schlagwortId: string): Observable<any[]> {
    // `${this.apiUrl}?populate=*&filters[schlagworte][id][$eq]=${schlagworteId}`
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?filters[schlagwortes][id][$eq]=${schlagwortId}`)
  }

  getExhibitorBySchwerpunktNeu(schwerpunktId: string): Observable<any[]> {
    // `${this.apiUrl}?populate=*&filters[schlagworte][id][$eq]=${schlagworteId}`
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?filters[schwerpus][id][$eq]=${schwerpunktId}`)
  }

  getExhibitorByName(name: string): Observable<any[]> {
    // populate=blocks
    // http://localhost:1337/api/posts?filters[slug][$eq]=entrada-de-prueba-2
    // http://localhost:1337/api/pages?sort[0]=Slug:asc&filters[Slug][$eq]=info
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?filters[name][$containsi]=${name}`)
  }

  getExhibitorComplex(search: any, targetPage: number): Observable<any[]> {
    let query = this.makeQuery(search, targetPage)
    let queryString = qs.stringify(query)
    queryString = decodeURIComponent(queryString)
    console.log("getExhibitorComplex query", queryString)
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?${queryString}`)
  }

  getStages(): Observable<StrapiListWrapper<BühneModel>> {
    return this.http.get<StrapiListWrapper<BühneModel>>(`${this.apiUrl}/api/buehnes`)
  }

  getSpeeches(
    dayId: number,
    stageIds: number[],
    signLanguage: boolean,
    pagination: Pagination,
  ): Observable<StrapiListWrapper<VortragModel>> {
    let query: any = {
      sort: [`timeslot.Start:asc`],
      filters: {
        ...{ buehne: { id: { $in: stageIds } } },
        ...{ tag: { id: { $eq: dayId } } },
      },
      populate: "*",
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page,
      },
    }
    if (signLanguage) {
      query.filters = {
        ...query.filters,
        ...{ GebaerdendolmetscherIn: signLanguage },
      }
    }
    var queryString = qs.stringify(query)
    return this.http.get<StrapiListWrapper<VortragModel>>(`${this.apiUrl}/api/vortrags?${queryString}`)
  }

  getDays(): Observable<StrapiListWrapper<TagModel>> {
    return this.http.get<StrapiListWrapper<TagModel>>(`${this.apiUrl}/api/tage`)
  }

  putExhibitor(id: number, exhibitor: ExhibitorModel): Observable<StrapiSingleWrapper<ExhibitorUpdateResponseModel>> {
    return this.http.put<StrapiSingleWrapper<ExhibitorUpdateResponseModel>>(
      `${this.apiUrl}/api/exhibitors/${id}?populate=*`,
      {
        data: exhibitor,
      },
    )
  }

  getUser(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/api/users/me?populate[exhibitor][populate][0]=all_banners&populate[exhibitor][populate][1]=all_documents&populate[exhibitor][populate][2]=Dokumente&populate[exhibitor][populate][3]=banner&populate[exhibitor][populate][4]=country`,
    )
  }

  getSchlagworte(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/schlagwortes`)
  }

  getSchwerpunkte(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/schwerpunktes`)
  }

  postFile(files: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/upload/check`, files)
  }

  deleteFile(id: number) {
    return this.http.delete<any>(`${this.apiUrl}/api/upload/check/${id}`)
  }

  donwloadFile(path: string) {
    return this.http.get(`${this.apiUrl}${path}`, {
      responseType: "blob",
    })
  }

  getCountries(): Observable<StrapiListWrapper<CountryModel>> {
    return this.http.get<StrapiListWrapper<CountryModel>>(`${this.apiUrl}/api/countries?pagination[pageSize]=1000`)
  }

  getLoginPage(): Observable<StrapiSingleWrapper<LoginPageModel>> {
    return this.http.get<StrapiSingleWrapper<LoginPageModel>>(`${this.apiUrl}/api/login-page?populate=deep`)
  }
  getExhibitorSelfservicePage(): Observable<StrapiSingleWrapper<SingletonPageModel>> {
    return this.http.get<StrapiSingleWrapper<SingletonPageModel>>(
      `${this.apiUrl}/api/exhibitor-selfservice-page?populate=deep`,
    )
  }
  geRegistrationPage(): Observable<StrapiSingleWrapper<SingletonPageModel>> {
    return this.http.get<StrapiSingleWrapper<SingletonPageModel>>(`${this.apiUrl}/api/registration-page?populate=deep`)
  }

  geProgrammPage(): Observable<StrapiSingleWrapper<ProgrammPageModel>> {
    return this.http.get<StrapiSingleWrapper<ProgrammPageModel>>(`${this.apiUrl}/api/programm-page?populate=deep,4`)
  }

  getWorkshopPage(): Observable<StrapiSingleWrapper<WorkshopPageModel>> {
    return this.http.get<StrapiSingleWrapper<WorkshopPageModel>>(`${this.apiUrl}/api/workshop-page?populate=deep,4`)
  }

  getAktiveStichworteSchlagworte(): Observable<StrapiSingleWrapper<WorkshopPageModel>> {
    return this.http.get<StrapiSingleWrapper<WorkshopPageModel>>(`${this.apiUrl}/api/aktive-schlagworte`)
  }

  getExhibitorSchlagworte(): any {
    //http://localhost:1337/api/exhibitors?populate=schlagwortes&populate[1]=schlagwortes&fields=id&filters[schlagwortes][$ne]=[]&populate=deep
    console.log("los");
    let query: any = {
      filters: {
      },
      populate: "schlagwortes",
      fields: ['id'],
      pagination: {
        pageSize: 2000,
        
      },

    }
    let queryString = qs.stringify(query)
    queryString = decodeURIComponent(queryString)
    console.log("getExhibitorComplex query", queryString)
    return this.http.get<any[]>(`${this.apiUrl}/api/exhibitors?${queryString}`)

  }

  getWorkshops(dayId: number,pagination: Pagination,): Observable<StrapiListWrapper<Workshop>> {
    let query: any = {
      sort: [`Start:asc`],
      filters: {
        ...{ tag: { id: { $eq: dayId } } },
      },
      populate: "*",
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page,
      },
    }
    var queryString = qs.stringify(query)
    return this.http.get<StrapiListWrapper<Workshop>>(`${this.apiUrl}/api/workshops?${queryString}`)
  }

  getWorkshop(id: number): Observable<StrapiSingleWrapper<Workshop>> {
    return this.http.get<StrapiSingleWrapper<Workshop>>(`${this.apiUrl}/api/workshop?${id}`)
  }

  createWorkshopAnmeldung(wa: WorkshopAnmeldungCreateRequest): Observable<StrapiSingleWrapper<WorkshopAnmeldung>> {
    return this.http.post<StrapiSingleWrapper<WorkshopAnmeldung>>(`${this.apiUrl}/api/workshop-anmeldungen`, {
      data: wa,
    })
  }

  makeQuery(search:  any, targetPage: number): any {
    // transform UI data into searchable parameters
    console.log("makeQuery search", search);
    search.schwerpunkte = search.schwerpunkte == "Alle" ? null : search.schwerpunkte;
    search.letter = search.letter == "Alle" ? null : search.letter;
    search.schlagworte = search.schlagworte == "Alle" ? null : search.schlagworte;
    search.name = search.name == "" ? null : search.name;
    search.sort = search.sort == "name" ? "name" : "koje";
    search.bereiche = search.bereiche.includes("Alle") ? null : search.bereiche.map((bereich: any) => bereich.replace('&', 'und'))
    let query: any = {
      sort: [`${search.sort}:asc`],
      filters: {
        ...(search.letter !== null && { name: { $startsWith: search.letter } }),
        ...(search.schlagworte !== null && { schlagwortes: { Schlagwort: search.schlagworte } }),
        ...(search.schwerpunkte !== null && { schwerpus: { Schwerpunkt: search.schwerpunkte } }),
        ...(search.name !== null && { name: { $containsi: search.name } }),
        ...(search.bereiche !== null && { bereich: { $in: search.bereiche } }),
      },
      populate: "deep",
      // fields: ['title'],
      pagination: {
        pageSize: 25,
        page: targetPage,
      },
    }

    return query
  }
}
